import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path
        d="M36.847 16.225a3.284 3.284 0 01-2.97 2.919 3.22 3.22 0 01-1.74-.324.822.822 0 00-.895.116l-3.788 3.19a.81.81 0 00-.269.78 3.275 3.275 0 01-.797 2.848 3.265 3.265 0 01-5.63-1.668.811.811 0 00-.617-.67l-1.854-.424a.808.808 0 00-.848.34 3.265 3.265 0 01-3.946 1.129 3.266 3.266 0 01-1.928-3.628 3.27 3.27 0 013.137-2.65 3.26 3.26 0 013.244 2.518.81.81 0 00.607.616l2.056.47a.809.809 0 00.81-.289c.3-.378.68-.686 1.112-.901a3.263 3.263 0 013.106.125.795.795 0 00.925-.064l3.623-3.05a.82.82 0 00.253-.853 3.262 3.262 0 01-.086-1.315 3.274 3.274 0 012.364-2.76 3.26 3.26 0 013.45 1.133 3.276 3.276 0 01.673 2.412h.008z"
        fill="url(#paint0_linear_309_167)"
      />
      <path
        d="M27.247 32.219v15.592a24.498 24.498 0 01-6.536 0V32.219c.02-.856.372-1.67.983-2.268a3.266 3.266 0 015.553 2.268z"
        fill="url(#paint1_linear_309_167)"
      />
      <path
        d="M31.239 18.936l-3.788 3.19a.811.811 0 00-.27.78 3.276 3.276 0 01-.797 2.848 3.265 3.265 0 01-5.63-1.668.812.812 0 00-.616-.67l-1.85-.424a.808.808 0 00-.849.34 3.264 3.264 0 01-3.946 1.129 3.266 3.266 0 01-1.928-3.628 3.27 3.27 0 013.137-2.65 3.26 3.26 0 013.244 2.518.81.81 0 00.607.616l2.056.47a.808.808 0 00.81-.289c.3-.378.68-.686 1.112-.901a3.263 3.263 0 013.106.125.795.795 0 00.925-.064l3.623-3.05a.82.82 0 00.253-.853 3.261 3.261 0 01.65-2.969 3.266 3.266 0 014.866-.15 3.274 3.274 0 01-2.076 5.516 3.22 3.22 0 01-1.74-.324.821.821 0 00-.9.108z"
        fill="url(#paint2_linear_309_167)"
      />
      <path
        d="M47.956 24.015a24.052 24.052 0 01-4.91 14.563A23.985 23.985 0 0130.33 47.18V24.576a3.278 3.278 0 012-3.09 3.265 3.265 0 014.308 1.815c.16.406.237.84.228 1.275v15.081a20.287 20.287 0 006.66-10.336 20.316 20.316 0 00-.53-12.29 20.28 20.28 0 00-7.523-9.723 20.226 20.226 0 00-23.404.299 20.28 20.28 0 00-7.272 9.913 20.315 20.315 0 00-.216 12.3 20.284 20.284 0 006.92 10.162v-9.844c0-.868.345-1.7.958-2.315a3.266 3.266 0 015.58 2.315v17.15a23.97 23.97 0 01-11.395-6.676A24.03 24.03 0 01.502 28.907c-.913-4.4-.576-8.97.975-13.187A24.015 24.015 0 019.27 5.048 23.944 23.944 0 0134.52 2.445a23.992 23.992 0 019.8 8.859 24.048 24.048 0 013.636 12.711z"
        fill="url(#paint3_linear_309_167)"
      />
      <path
        d="M44.433 36.552a24.097 24.097 0 01-5.074 5.888 1.523 1.523 0 01-2.494-1.179v-1.599a20.313 20.313 0 005.778-7.764l1.79 4.654z"
        fill="url(#paint4_linear_309_167)"
      />
      <path
        d="M39.923 41.921c.178-.149.354-.3.528-.454-.353.334-.718.66-1.09.973a1.523 1.523 0 01-2.26-.36 1.53 1.53 0 01-.236-.819v-1.599c.19-.158.379-.32.563-.486v1.565a1.529 1.529 0 00.873 1.384 1.52 1.52 0 001.622-.204z"
        fill="url(#paint5_linear_309_167)"
      />
      <path
        d="M3.944 36.892a24.097 24.097 0 005.073 5.889 1.522 1.522 0 002.495-1.18v-1.598a20.313 20.313 0 01-5.778-7.765l-1.79 4.654z"
        fill="url(#paint6_linear_309_167)"
      />
      <path
        d="M8.454 42.263c-.178-.149-.354-.3-.528-.455.353.335.717.659 1.091.973a1.523 1.523 0 002.495-1.178v-1.6a14.858 14.858 0 01-.563-.487v1.565a1.528 1.528 0 01-.872 1.386 1.52 1.52 0 01-1.623-.204z"
        fill="url(#paint7_linear_309_167)"
      />
      <path
        d="M35.887 18.224a3.277 3.277 0 00-1.842-4.034 3.263 3.263 0 00-3.554.686 3.272 3.272 0 013.439-2.241 3.261 3.261 0 012.429 1.507 3.276 3.276 0 01-.472 4.082z"
        fill="url(#paint8_linear_309_167)"
      />
      <path
        d="M12.479 23.785a3.277 3.277 0 011.84-4.036 3.262 3.262 0 013.556.684 3.273 3.273 0 00-1.985-2.059 3.263 3.263 0 00-3.883 1.326 3.276 3.276 0 00.472 4.082v.003z"
        fill="url(#paint9_linear_309_167)"
      />
      <path
        d="M26.805 21.892a3.276 3.276 0 01-2.347 3.287 3.262 3.262 0 01-3.745-1.503 3.278 3.278 0 001.363 2.516 3.265 3.265 0 005.16-2.896 3.276 3.276 0 00-.431-1.404z"
        fill="url(#paint10_linear_309_167)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_309_167"
          x1={23.8646}
          y1={0.934633}
          x2={24.693}
          y2={46.9221}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02E0E7" />
          <stop offset={0.11} stopColor="#02D7E8" />
          <stop offset={0.28} stopColor="#03BEEA" />
          <stop offset={0.51} stopColor="#0496EE" />
          <stop offset={0.77} stopColor="#055EF3" />
          <stop offset={1} stopColor="#0626F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_309_167"
          x1={23.2772}
          y1={0.946046}
          x2={24.1072}
          y2={46.9319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02E0E7" />
          <stop offset={0.11} stopColor="#02D7E8" />
          <stop offset={0.28} stopColor="#03BEEA" />
          <stop offset={0.51} stopColor="#0496EE" />
          <stop offset={0.77} stopColor="#055EF3" />
          <stop offset={1} stopColor="#0626F8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_309_167"
          x1={23.8646}
          y1={0.934609}
          x2={24.693}
          y2={46.9221}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02E0E7" />
          <stop offset={0.11} stopColor="#02D7E8" />
          <stop offset={0.28} stopColor="#03BEEA" />
          <stop offset={0.51} stopColor="#0496EE" />
          <stop offset={0.77} stopColor="#055EF3" />
          <stop offset={1} stopColor="#0626F8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_309_167"
          x1={23.5652}
          y1={0.941139}
          x2={24.3952}
          y2={46.927}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02E0E7" />
          <stop offset={0.11} stopColor="#02D7E8" />
          <stop offset={0.28} stopColor="#03BEEA" />
          <stop offset={0.51} stopColor="#0496EE" />
          <stop offset={0.77} stopColor="#055EF3" />
          <stop offset={1} stopColor="#0626F8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_309_167"
          x1={43.2531}
          y1={35.0178}
          x2={36.809}
          y2={41.2909}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.96} stopOpacity={0.47} />
          <stop offset={0.99} stopOpacity={0.5} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_309_167"
          x1={38.8058}
          y1={40.4196}
          x2={37.1454}
          y2={42.0352}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.96} stopOpacity={0.47} />
          <stop offset={0.99} stopOpacity={0.5} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_309_167"
          x1={5.12381}
          y1={35.3584}
          x2={11.5679}
          y2={41.6315}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.96} stopOpacity={0.47} />
          <stop offset={0.99} stopOpacity={0.5} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_309_167"
          x1={9.57111}
          y1={40.76}
          x2={11.2316}
          y2={42.3773}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.96} stopOpacity={0.47} />
          <stop offset={0.99} stopOpacity={0.5} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_309_167"
          x1={32.2637}
          y1={13.3741}
          x2={36.5619}
          y2={17.2687}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={0.14} stopColor="#fff" stopOpacity={0.07} />
          <stop offset={0.43} stopColor="#fff" stopOpacity={0.26} />
          <stop offset={0.48} stopColor="#fff" stopOpacity={0.3} />
          <stop offset={0.82} stopColor="#fff" stopOpacity={0.09} />
          <stop offset={0.99} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_309_167"
          x1={16.102}
          y1={18.9346}
          x2={11.8054}
          y2={22.8292}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={0.14} stopColor="#fff" stopOpacity={0.07} />
          <stop offset={0.43} stopColor="#fff" stopOpacity={0.26} />
          <stop offset={0.48} stopColor="#fff" stopOpacity={0.3} />
          <stop offset={0.82} stopColor="#fff" stopOpacity={0.09} />
          <stop offset={0.99} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint10_linear_309_167"
          x1={31.3254}
          y1={32.9152}
          x2={36.4991}
          y2={30.3368}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={0.14} stopColor="#fff" stopOpacity={0.07} />
          <stop offset={0.43} stopColor="#fff" stopOpacity={0.26} />
          <stop offset={0.48} stopColor="#fff" stopOpacity={0.3} />
          <stop offset={0.82} stopColor="#fff" stopOpacity={0.09} />
          <stop offset={0.99} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
